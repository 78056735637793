import _ from 'lodash'

let instance = null

export default class AttendeesByTimeMapper {
  constructor() {
    if (!instance) {
      instance = this
    }

    return instance
  }

  static getInstance() {
    return new AttendeesByTimeMapper()
  }

  /**
   * First, sorts by time (bigger timeNumber first), then maps  attendeesByDate to attendees by time.
   * @param {{}[]} attendeesByDate
   * @return {Promise<{}>}
   */
  async mapToAttendeesByTime(attendeesByDate) {
    const sortedByTimeNumber = _.cloneDeep(attendeesByDate)
    sortedByTimeNumber.sort((a, b) => b.timeNumber - a.timeNumber)

    const attendeesByTime = this._getAttendeesByTime(sortedByTimeNumber)

    return attendeesByTime
  }

  /**
   * Gets recursively attendees availability by time from sorted by timeNumber attendees availability data.
   * @param {{}[]} sortedByTimeNumber
   * @return {{}}
   * @private
   */
  _getAttendeesByTime(sortedByTimeNumber) {
    const attendeesAvailabilityByTime = {}

    if (sortedByTimeNumber.length > 1) {
      const sortedByTimeNumberClone = [...sortedByTimeNumber]
      const firstItem = sortedByTimeNumberClone.shift()
      attendeesAvailabilityByTime[firstItem.time] = [...sortedByTimeNumber]

      // TODO make async on each recursion call to prevent freezing on big data
      const recursionResult = this._getAttendeesByTime(sortedByTimeNumberClone)

      if (!_.isEmpty(recursionResult)) {
        _.merge(attendeesAvailabilityByTime, recursionResult)
      }
    }

    return attendeesAvailabilityByTime;
  }
}