<template>
  <div>
    <v-row justify="center" class="text-center">
      <h1>{{ $metaInfo.title }}</h1>

      <v-form
          ref="form"
          lazy-validation
          class="meet-create"
      >
        <v-text-field
            v-model="name"
            :rules="nameRules"
            :label="$t('Name (to beer, to coffee...)')"
            required
        ></v-text-field>

        <br>

        <set-location @done="location = $event" ref="setLocation"></set-location>
        <div>
          <div>
            {{$t('Where?')}}
            <a v-if="isLocationSet" href="#" @click.prevent="$refs.setLocation.showDialog()">{{$t('Edit')}}</a>
          </div>
          <div>
            <static-map v-if="isLocationSet" :lat="location.lat" :lng="location.lng"></static-map>
            <a v-else href="#" @click.prevent="$refs.setLocation.showDialog()">{{ $t('Choose location') }}</a>
          </div>
        </div>

      </v-form>

    </v-row>

    <v-row justify="center" class="clear-storage">
      <v-btn
          color="secondary"
          @click="clearStorage"
      >
        Clear Local Storage
      </v-btn>
    </v-row>

    <bottom-button @click="create">
      {{ $t('Create') }}
    </bottom-button>
  </div>
</template>

<script>

import BottomButton from '@/components/Widgets/BottomButton'
import SetLocation from '@/views/Meeting/Create/SetData/SetLocation'
import randomstring from 'randomstring'
import dateRangeTextMixin from '@/mixins/dateRangeTextMixin'
import DateHelper from '@/helpers/DateHelper'
import StaticMap from '@/components/Widgets/Maps/Location/StaticMap'

export default {
  name: 'MeetingCreate',

  metaInfo() {
    return {
      title: this.$t('Meeting Create'),
    }
  },

  components: {SetLocation, BottomButton, StaticMap},

  mixins: [dateRangeTextMixin],

  data() {
    return {
      name: null,

      nameRules: [
        v => !!v || 'Name is required',
        v => (v && v.length <= 10) || 'Name must be less than 10 characters',
      ],

      location: null,
    }
  },

  computed: {
    isLocationSet() {
      return !!this.location
    },

    dateRange() {
      const now = new Date()
      const endDate = DateHelper.getTheEndOfNextWeek(now);

      return [now, endDate]
    },
  },

  methods: {
    create() {
      const id = randomstring.generate();
      localStorage.setItem(id, JSON.stringify({
        name: this.name, dateRange: this.dateRange, location: this.location, isCreator: true,
      }))

      this.$router.push('/meeting/' + id)
    },

    clearStorage() {
      localStorage.clear()
    },
  },
}
</script>

<style scoped>
.meet-create {
  width: 100%;
}
.clear-storage {
  margin-top: 100px;
}
</style>