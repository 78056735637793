<template>
  <div class="home">

    <v-btn
        depressed
        color="primary"
        to="/meeting/create"
    >
      {{ buttonText }}
    </v-btn>
  </div>
</template>

<script>
// @ is an alias to /src


export default {
  name: 'Home',
  components: {},

  computed: {
    buttonText() {
      return this.$t('Create meeting')
    },
  },
}
</script>
