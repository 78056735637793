<template>
  <div>
    <main-title title="Set your availability"></main-title>

    <v-row justify="center">
      <v-col
          cols="12"
          sm="10"
          md="8"
          lg="6"
      >
        <p>
          {{ meet.name }}
          <v-btn
              color="primary"
              text
          >
            copy url
          </v-btn>
        </p>

        <v-text-field
            ref="name"
            v-model="attendeeName"
            :rules="[() => !!attendeeName || 'This field is required']"
            :error-messages="errorMessages"
            label="Your Name"
            required
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row justify="center">
      <date-picker-widget
          v-model="dates"
          :min="minMeetDate"
          :max="maxMeetDate"
          :multiple="true"
          :allowed-dates="allowedDates"
          :show-adjacent-months="false"
      ></date-picker-widget>
    </v-row>

    <v-row justify="center">
      <p>Please, first select a date (or range of the dates) and then set a time</p>
    </v-row>

    <v-row justify="center">
      <time-range-widget
          v-if="dates"
          v-model="timeRange"
          :set-end-automatically="true"
      ></time-range-widget>
    </v-row>

    <v-row justify="center" v-if="timeRange.length === 2">
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="primary"
            @click="setTime"
        >
          Set time
        </v-btn>
      </v-card-actions>
    </v-row>

    <v-row justify="center" v-if="timeSetData.slots.length">
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="secondary"
            @click="saveTime"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-row>

    <v-row justify="center" v-if="timeSetData.slots.length">
      <v-col
          cols="12"
          sm="12"
          md="12"
          lg="12"
      >
        <meet-calendar></meet-calendar>
      </v-col>
    </v-row>

    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
  </div>
</template>

<script>
import MainTitle from '@/components/Content/MainTitle'
import {mapGetters, mapMutations} from 'vuex'
import DatePickerWidget from '@/components/Widgets/DatePickerWidget'
import TimeRangeWidget from '@/components/Widgets/TimeRangeWidget'
import _ from 'lodash'
import MeetCalendar from '@/views/MeetingSample/Widgets/MeetCalendar'

// TODO finish this

export default {
  name: 'MeetingSetTime',

  components: {MainTitle, DatePickerWidget, TimeRangeWidget, MeetCalendar},

  computed: {
    ...mapGetters({
      firstDayOfWeek: 'settings/getFirstDayOfWeek',
      meet: 'meet/getMeet',
      minMeetDate: 'meet/getMinDate',
      maxMeetDate: 'meet/getMaxDate',
    }),
  },

  data: () => ({
    allowedDates: null,
    attendeeName: null,
    errorMessages: [],
    dates: null,
    timeRange: [],

    setDates: [],

    timeSetData: {
      attendeeName: null,
      slots: [],
    },
  }),

  created() {
    if (!this.meet.name || !this.meet.dates || !this.meet.dates.length || !this.meet.location) {
      let fromStorage = sessionStorage.getItem('meet')
      fromStorage = fromStorage ? JSON.parse(fromStorage) : null

      if (fromStorage) {
        this.setMeet(fromStorage)
      }
    }
  },

  methods: {
    ...mapMutations({
      setMeet: 'meet/setMeet',
      addAttendee: 'meet/addAttendee',
    }),

    setTime() {
      this.timeSetData.slots.push({
        dates: this.dates,
        timeRange: this.timeRange,
      })

      this.setDates = _.concat(this.setDates, this.dates)

      this.dates = null
      this.timeRange = []

      this.allowedDates = (val) => !this.setDates.includes(val)
    },

    saveTime() {
      this.timeSetData.attendeeName = this.attendeeName
      this.addAttendee(this.timeSetData)
    },
  },
}
</script>

<style scoped>

</style>