<template>
  <div class="map-container">
    <l-map
        :zoom="zoom"
        :center="center"
        :style="mapStyle"
        ref="lmap"
        @click="mapClick"
    >
      <l-tile-layer
          :url="url"
          :attribution="attribution"
      />
      <l-marker :lat-lng="marker" :draggable="true" @update:lat-lng="updateLatLng"/>
      <l-icon-default :image-path="path"/>
    </l-map>
  </div>
</template>

<script>
import 'leaflet/dist/leaflet.css'
import {latLng, Icon} from 'leaflet'
import {LMap, LTileLayer, LMarker, LIconDefault} from 'vue2-leaflet'

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

// TODO remove hardcode. Start to get user's geo position. Use vue2-leaflet-locatecontrol plugin
const lat = 49.83826
const lng = 24.02324

export default {
  name: 'LocationPicker',

  components: {
    LMap,
    LTileLayer,
    LMarker,
    LIconDefault
  },

  data() {
    return {
      zoom: 13,
      path: '/images/',
      center: [lat, lng],
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      marker: latLng(lat, lng),
      location: {lat, lng},
    };
  },

  computed: {
    mapStyle() {
      const height = window.innerHeight * 0.7

      return `height: ${height}px; width: 100%`
    },
  },

  methods: {
    updateLatLng(latLang) {
      this.location = {lat: latLang.lat, lng: latLang.lng};
    },
    mapClick(data) {
      this.marker = data.latlng
    },
  },
}
</script>

<style scoped>

</style>