<template>
  <div>
    <main-title title="Create new meeting"></main-title>

    <v-form>
      <v-row justify="center">
        <v-col
            cols="12"
            sm="10"
            md="8"
            lg="6"
        >
          <v-card ref="form">
            <v-card-text>
              <v-text-field
                  ref="name"
                  v-model="meet.name"
                  :rules="[() => !!meet.name || 'This field is required']"
                  :error-messages="errorMessages"
                  label="Name"
                  required
              ></v-text-field>

              <date-range-widget v-model="meet.date"></date-range-widget>

              <v-text-field
                  ref="city"
                  v-model="meet.location"
                  :rules="[() => !!meet.location || 'This field is required']"
                  label="Location"
                  required
              ></v-text-field>
            </v-card-text>
            <v-divider class="mt-12"></v-divider>
            <v-card-actions>
<!--              <v-btn text>-->
<!--                Cancel-->
<!--              </v-btn>-->
              <v-spacer></v-spacer>
              <v-btn
                  color="primary"
                  text
                  @click="submit"
              >
                Submit
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-form>

<!-- TODO form here  -->

  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import DateRangeWidget from '@/views/MeetingSample/Widgets/DateRangeWidget'
import MainTitle from '@/components/Content/MainTitle'

export default {
  name: 'MeetingCreate',

  components: {
    MainTitle,
    DateRangeWidget,
  },

  data: () => ({
    errorMessages: [],
  }),

  computed: {
    ...mapGetters({
      meet: 'meet/getMeet',
    }),
  },

  methods: {
    submit() {
      console.log('submit')
      sessionStorage.setItem('meet', JSON.stringify(this.meet))

      this.$router.push('/meeting/set-time-simple-sample')
    },
  },
};
</script>

<style scoped>

</style>