let instance = null

/**
 * AvailabilityColorService class. Allows to get availability color depended on percent of possible attendees.
 * From green to red.
 */
export default class AvailabilityColorService {
  constructor() {
    if (!instance) {
      instance = this
    }

    return instance
  }

  static getInstance() {
    return new AvailabilityColorService()
  }

  /**
   * Gets availability color depended on percent of possible attendees. From green (120) to red.
   * @param {number} value Value from 0 to 1.
   * @return {string} Returns hsl() function. Use it as background color of HTML element.
   */
  getColor(value) {
    const hue = (value * 120).toString(10);

    return ['hsl(', hue, ',100%,50%)'].join('');
  }
}
