let instance = null

/**
 * Maps
 */
export default class AttendeesByDateMapper {
  constructor() {
    if (!instance) {
      instance = this
    }

    return instance
  }

  static getInstance() {
    return new AttendeesByDateMapper()
  }

  /**
   * Maps attendeesData to attendees by date. Sorts them by attendees length (count).
   * @param {{}} attendeesData
   * @return {Promise<{[p: string]: unknown}>}
   */
  async mapAndSort(attendeesData) {
    const mappedData = this._mappedData(attendeesData)
    const sortedData = this._sortedData(mappedData)

    return sortedData
  }

  _mappedData(data) {
    const mappedData = {}

    for (const [attendee, dateWithTime] of Object.entries(data)) {
      for (const [date, time] of Object.entries(dateWithTime)) {
        if (mappedData[date] === undefined) {
          mappedData[date] = []
        }

        mappedData[date].push({attendee, time, timeNumber: Number.parseInt(time.replace(':', ''))})
      }
    }

    return mappedData;
  }

  _sortedData(mappedData) {
    const sortedEntries = Object.entries(mappedData)
      .sort((a, b) => b[1].length - a[1].length)

    const sortedData = Object.fromEntries(sortedEntries)

    return sortedData
  }
}
