<template>
  <v-row justify="center" class="title-wrap">
    <h1>{{ title }}</h1>
  </v-row>
</template>

<script>
export default {
  name: 'MainTitle',

  props: {
    title: {
      type: String,
    },
  },
}
</script>

<style scoped>
  .title-wrap {
    margin-bottom: 20px;
  }
</style>