<template>
  <v-app>
    <app-header></app-header>

    <v-main>
      <v-container>
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import AppHeader from '@/components/App/AppHeader';

export default {
  name: 'App',

  components: {
    AppHeader,
  },

  data: () => ({
    drawer: false,
  }),
};
</script>
