import Vue from 'vue'
import VueRouter from 'vue-router'
import Meta from 'vue-meta'
import Home from '../views/Home.vue'
import MeetingCreateSample from '@/views/MeetingSample/MeetingCreate'
import MeetingSetTimeSample from '@/views/MeetingSample/MeetingSetTime'
import MeetingCreate from '@/views/Meeting/Create/MeetingCreate'
import MeetingView from '@/views/Meeting/View/MeetingView'
import NotFoundComponent from '@/views/NotFoundComponent'

Vue.use(VueRouter)
Vue.use(Meta)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/meeting/create-simple-sample',
    name: 'Meeting Create Sample',
    component: MeetingCreateSample,
  },
  {
    path: '/meeting/set-time-simple-sample',
    name: 'Meeting Set Time Sample',
    component: MeetingSetTimeSample,
  },
  {
    path: '/meeting/create',
    name: 'Meeting Create',
    component: MeetingCreate,
  },
  {
    path: '/meeting/:id',
    name: 'Meeting View',
    component: MeetingView,
    props: true,
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/:catchAll(.*)',
    component: NotFoundComponent,
    name: 'NotFound'
  }
]

const router = new VueRouter({
  mode: 'history',
  routes,
})

export default router
