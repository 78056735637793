<template>
  <v-row
      justify="center"
      align="center"
  >
    <v-col style="width: 350px; flex: 0 1 auto;">
      <h2>Can start meet from:</h2>
      <time-picker-widget
          v-model="start"
          @input="newStart"
          :max="end"
      ></time-picker-widget>
    </v-col>
    <v-col style="width: 350px; flex: 0 1 auto;">
      <h2>Can start meet to:</h2>
      <time-picker-widget
          v-model="end"
          @input="newEnd"
          :min="start"
          :readonly="!start"
      ></time-picker-widget>
    </v-col>
  </v-row>
</template>

<script>
import TimePickerWidget from '@/components/Widgets/TimePickerWidget'

export default {
  name: 'TimeRangeWidget',

  components: {TimePickerWidget},

  props: {
    value: {
      type: Array,
      required: true,
    },

    setEndAutomatically: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    start: null,
    end: null,
  }),

  created() {
    this.start = this.value[0]
    this.end = this.value[1]
  },

  methods: {
    newStart() {
      if (this.setEndAutomatically) {
        this.end = '00:00'
      }

      this.$emit('input', [this.start, this.end])
    },

    newEnd() {
      console.log('newEnd', this.start, this.end)
      this.$emit('input', [this.start, this.end])
    },
  },
}
</script>

<style scoped>

</style>