<template>
  <div class="small-container">
    <time-picker-widget
        v-model="selectedTime"
        @input="chooseDisabled = false"
    ></time-picker-widget>

    <v-card-actions class="justify-center">
      <v-btn
          color="primary"
          :disabled="chooseDisabled"
          @click="$emit('set', selectedTime)"
      >
        {{$t('Set')}}
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import TimePickerWidget from '@/components/Widgets/TimePickerWidget'

export default {
  name: 'TimePicker',

  components: {TimePickerWidget},

  data: () => ({
    selectedTime: null,
    chooseDisabled: true,
  }),
}
</script>

<style scoped>

</style>