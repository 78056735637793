<template>
  <div>
    <div class="availability-view-container" v-if="attendeesByDateData">
      <availability-item v-for="(attendeesByDate, date) in attendeesByDateData"
                         :key="date"
                         :date="date"
                         :attendees-by-date="attendeesByDate"></availability-item>
    </div>

    <!-- TODO remove this -->
    <div class="attendees-data" v-if="attendeesByDateData">
      <vue-json-pretty :data="attendeesByDateData"></vue-json-pretty>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import AttendeesByDateMapper from '@/services/Meet/AttendeesByDateMapper'
import VueJsonPretty from 'vue-json-pretty'
import AvailabilityItem from '@/views/Meeting/View/Components/AvailabilityView/Components/AvailabilityItem'

export default {
  name: 'AvailabilityView',

  components: {VueJsonPretty, AvailabilityItem},

  props: {
    attendeesData: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    attendeesByDateData: null,
  }),

  watch: {
    attendeesData() {
      this.mapAndSortAttendeesData()
    },
  },

  created() {
    this.mapAndSortAttendeesData()
  },

  methods: {
    mapAndSortAttendeesData() {
      if (!_.isEmpty(this.attendeesData)) {
        AttendeesByDateMapper.getInstance()
            .mapAndSort(this.attendeesData)
            .then(computedData => this.attendeesByDateData = computedData)
            .catch(err => console.error(err))
      }
    },
  },
}
</script>

<style scoped lang="scss">
.attendees-data {
  margin: 50px 0;
}
</style>