<template>
  <bottom-content>
    <v-btn
        class="bottom-button"
        color="primary"
        x-large
        @click="$emit('click')"
    >
      <slot>
        {{ $t('Done') }}
      </slot>
    </v-btn>
  </bottom-content>
</template>

<script>
import BottomContent from '@/components/Content/BottomContent'

export default {
  name: 'BottomButton',

  components: {BottomContent},
}
</script>

<style scoped lang="scss">
.bottom-button {
  height: 56px;
  width: 100%;
  border-radius: 0;

  @media screen and (min-width: 1264px) {
    width: auto;
    float: right;
  }
}
</style>