<template>
  <v-row>
    <v-col
        cols="12"
        sm="6"
    >
      <v-text-field
          v-model="dateRangeText"
          label="Meet time range"
          prepend-icon="mdi-calendar"
          readonly
      ></v-text-field>
    </v-col>
    <v-col
        cols="12"
        sm="6"
    >
      <date-picker-widget
          :value="value"
          @input="$emit('input', $event)"
          :range="true"
          :min="nowDate"
          :max="getEndDate"
      ></date-picker-widget>
    </v-col>
  </v-row>
</template>

<script>
import DateHelper from '@/helpers/DateHelper'
import DatePickerWidget from '@/components/Widgets/DatePickerWidget'

export default {
  name: 'DateRangeWidget',

  components: {DatePickerWidget},

  props: {
    value: {
      type: Array,
      required: true,
    },
  },

  computed: {
    date: () => new Date(),
    nowDate() {
      return DateHelper.toIsoStringInCurrentTimezone(this.date)
    },
    dateRangeText() {
      return this.value.join(' ~ ')
    },
    getEndDate() {
      const endDate = new Date(this.date.getFullYear(), this.date.getMonth() + 1, 10);

      return DateHelper.toIsoStringInCurrentTimezone(endDate)
    },
  },
}
</script>

<style scoped>

</style>