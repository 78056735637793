<template>
  <div>
    <data-set-dialog @done="done" ref="dialog" :title="$t('Set availability')">
      <h4 class="text-center">{{$t('Choose days and set time to these days')}}</h4>

      <day-picker v-if="!daysSet.length"
                  :date-range="dateRange"
                  @set="daysSet = $event"
                  :allowed-dates="allowedDates"></day-picker>
      <time-picker v-else @set="setTime"></time-picker>

      <div class="container">
        <time-with-days :time-with-days="timeWithDaysObj"></time-with-days>
      </div>
    </data-set-dialog>
  </div>
</template>

<script>
import DayPicker from '@/views/Meeting/View/Components/Availability/DayPicker'
import DataSetDialog from '@/components/Widgets/Dialogs/DataSetDialog'
import TimePicker from '@/views/Meeting/View/Components/Availability/TimePicker'
import TimeWithDays from '@/views/Meeting/View/Components/TimeWithDays'

export default {
  name: 'SetAvailability',

  components: {DataSetDialog, DayPicker, TimePicker, TimeWithDays},

  props: {
    dateRange: {
      type: Array,
      required: true,
    },
  },

  data: () => ({
    daysSet: [],
    alreadySetDays: [],
    timeWithDaysObj: {},
    allowedDates: null,
  }),

  methods: {
    showDialog() {
      this.$refs.dialog.showDialog()
    },

    setTime(chosenTime) {
      for (const daysSetElement of this.daysSet) {
        this.timeWithDaysObj[daysSetElement] = chosenTime
      }

      this.alreadySetDays.push(...this.daysSet)
      this.allowedDates = (date) => !this.alreadySetDays.includes(date)

      this.daysSet = [];
    },

    done() {
      this.$emit('done', this.timeWithDaysObj)
    },
  },
}
</script>

<style scoped>

</style>