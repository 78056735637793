<template>
  <div class="bottom-container">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'BottomContent',
}
</script>

<style scoped lang="scss">
.bottom-container {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;

  @media screen and (min-width: 1264px) {
    width: auto;
  }
}
</style>