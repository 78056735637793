import DateHelper from '@/helpers/DateHelper'

export default {
  namespaced: true,

  state: {
    meet: {
      name: null,
      date: [],
      location: null,
    },

    attendees: [],
  },

  getters: {
    getMeet: (state) => state.meet,
    getMinDate: (state) => DateHelper.minFromStrDates(state.meet.date),
    getMaxDate: (state) => DateHelper.maxFromStrDates(state.meet.date),
    getAttendees: (state) => state.attendees,
  },

  mutations: {
    setMeet: (state, meet) => state.meet = meet,
    addAttendee: (state, attendee) => state.attendees.push(attendee),
  },
}
