<template>
  <div>
    <v-dialog
        v-model="dialog"
        :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-toolbar
            dark
            color="primary"
        >
          <v-btn
              icon
              dark
              @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ title }}</v-toolbar-title>
        </v-toolbar>

        <slot></slot>

        <bottom-button @click="done"></bottom-button>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import BottomButton from '@/components/Widgets/BottomButton'

/**
 * See SetLocation ot know how to use
 */
export default {
  name: 'SetLocation',

  components: {BottomButton},

  props: {
    title: String,
  },

  data: () => ({
    dialog: false,
  }),

  methods: {
    showDialog() {
      this.dialog = true
    },

    done() {
      this.dialog = false
      this.$emit('done')
    },
  },
}
</script>

<style scoped>

</style>