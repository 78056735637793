<template>
  <div class="availability-item">
    <div class="date">
      {{ date }}
    </div>

    <template v-if="attendeesAvailabilityByTime">
      <div class="attendees-by-time" v-for="(attendees, time) in attendeesAvailabilityByTime" :key="time">
        <div class="time" :style="{backgroundColor: getAvailabilityColor(attendees.length)}">{{ time }}</div>
        <div class="attendees-container">
          <div class="attendee" v-for="attendee in attendees" :key="attendee.attendee">
            {{ attendee.attendee }}
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import AttendeesByTimeMapper from '@/services/Meet/AttendeesByTimeMapper'
import _ from 'lodash'
import AvailabilityColorService from '@/services/Style/AvailabilityColorService'

export default {
  name: 'AvailabilityItem',

  props: {
    date: {
      type: String,
      required: true,
    },
    attendeesByDate: {
      type: Array,
      required: true,
    },
  },

  data: () => ({
    attendeesAvailabilityByTime: null,
  }),

  computed: {
    mostAttendees() {
      let mostAttendees = null

      if (this.attendeesAvailabilityByTime) {
        mostAttendees = this.attendeesAvailabilityByTime[Object.keys(this.attendeesAvailabilityByTime)[0]]
      }

      return mostAttendees
    },
  },

  created() {
    this.computeAttendeesAvailabilityByTime()
  },

  methods: {
    computeAttendeesAvailabilityByTime() {
      AttendeesByTimeMapper.getInstance()
          .mapToAttendeesByTime(this.attendeesByDate)
          .then(attendeesAvailabilityByTime => this.attendeesAvailabilityByTime = attendeesAvailabilityByTime)
          .catch(err => console.error(err))
    },
    getAvailabilityColor(currentAttendeesLength) {
      if (this.mostAttendees) {
        const percent = currentAttendeesLength / this.mostAttendees.length
        const color = this.getColor(_.round(percent, 2), 1, this.mostAttendees.length)

        return color
      }
    },
    getColor(value) {
      return AvailabilityColorService.getInstance().getColor(value);
    },
  },
}
</script>

<style scoped lang="scss">
$borderLightGray: 1px solid #e0e0e0;
$borderRadius: 3px;

.availability-item {
  border: 2px solid #e0e0e0;
  border-radius: $borderRadius;
  margin-bottom: 15px;

  .attendees-by-time {
    border-top: $borderLightGray;
    display: grid;
    grid-template-columns: 70px auto;

    .time {
      align-self: center;
    }

    .attendees-container {
      border-left: $borderLightGray;
      display: flex;

      .attendee {
        border: 1px solid #e0e0e0;
        margin: 10px;
        padding: 5px 10px;
        border-radius: $borderRadius;
      }
    }
  }
}
</style>