<template>
  <div class="small-container">
    <date-picker-widget
        v-model="dates"
        :min="minMeetDate"
        :max="maxMeetDate"
        :multiple="true"
        :allowed-dates="allowedDates"
        :show-adjacent-months="false"
        :no-title="true"
        :show-current="false"
    ></date-picker-widget>

    <v-card-actions class="justify-center">
      <v-btn
          color="primary"
          :disabled="setTimeDisabled"
          @click="$emit('set', dates)"
      >
        {{$t('Set')}}
      </v-btn>
    </v-card-actions>

  </div>
</template>

<script>
import DatePickerWidget from '@/components/Widgets/DatePickerWidget'
import DateHelper from '@/helpers/DateHelper'

export default {
  name: 'DayPicker',

  components: {DatePickerWidget},

  props: {
    dateRange: {
      type: Array,
      required: true,
    },

    allowedDates: {
      type: [Function, null],
    },
  },

  data: () => ({
    dates: [],
    setDateTimeItems: null,
  }),

  computed: {
    minMeetDate() {
      return DateHelper.minFromStrDates(this.dateRange)
    },
    maxMeetDate() {
      return DateHelper.maxFromStrDates(this.dateRange)
    },
    setTimeDisabled() {
      return this.dates.length === 0
    },
  },
}
</script>

<style scoped>

</style>