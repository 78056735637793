const FORMAT_ISO = 'iso'
const FORMAT_DATE = 'date'
const FORMAT_TIMESTAMP = 'timestamp'

export default class DateHelper {
  static get FORMAT_ISO() {
    return FORMAT_ISO
  }

  static get FORMAT_DATE() {
    return FORMAT_DATE
  }

  static get FORMAT_TIMESTAMP() {
    return FORMAT_TIMESTAMP
  }

  /**
   * Converts Date to ISO string in current timezone.
   * @param {Date} date
   * @return {string}
   */
  static toIsoStringInCurrentTimezone(date) {
    const tzo = -date.getTimezoneOffset(),
      dif = tzo >= 0 ? '+' : '-',
      pad = function (num) {
        const norm = Math.floor(Math.abs(num))
        return (norm < 10 ? '0' : '') + norm;
      }

    return date.getFullYear() +
      '-' + pad(date.getMonth() + 1) +
      '-' + pad(date.getDate()) +
      'T' + pad(date.getHours()) +
      ':' + pad(date.getMinutes()) +
      ':' + pad(date.getSeconds()) +
      dif + pad(tzo / 60) +
      ':' + pad(tzo % 60);
  }

  static _minMaxFromStrDates(strDates, methodName, format = DateHelper.FORMAT_ISO) {
    if (!Array.isArray(strDates)) {
      return null
    }

    if (!['max', 'min'].includes(methodName)) {
      throw new Error('Only "min" and "max" are allowed')
    }

    const minDateTimestamp = Math[methodName].apply(null, strDates.map(function (strDate) {
      return +new Date(strDate);
    }))

    let result = null

    switch (format) {
      case DateHelper.FORMAT_ISO:
        result = DateHelper.toIsoStringInCurrentTimezone(new Date(minDateTimestamp))
        break
      case DateHelper.FORMAT_DATE:
        result = new Date(minDateTimestamp)
        break
      case DateHelper.FORMAT_TIMESTAMP:
        result = minDateTimestamp
        break
      default:
        result = null
    }

    return result
  }

  /**
   * Gets min date from `strDates`
   * @param {Array} strDates Array of string dates.
   * @param {string} format One of allowed formats `DateHelper.FORMAT_...`
   * @return {string|Date|number} Returns min date in `format`.
   */
  static minFromStrDates(strDates, format = DateHelper.FORMAT_ISO) {
    return this._minMaxFromStrDates(strDates, 'min', format)
  }

  /**
   * Gets max date from `strDates`
   * @param {Array} strDates Array of string dates.
   * @param {string} format One of allowed formats `DateHelper.FORMAT_...`
   * @return {string|Date|number} Returns min date in `format`.
   */
  static maxFromStrDates(strDates, format = DateHelper.FORMAT_ISO) {
    return this._minMaxFromStrDates(strDates, 'max', format)
  }

  static getDaysFromStrRange(strDateRange) {
    const minDate = DateHelper.minFromStrDates(strDateRange, DateHelper.FORMAT_DATE)
    const maxDate = DateHelper.maxFromStrDates(strDateRange, DateHelper.FORMAT_DATE)

    const isoDays = []
    let currentDate = minDate

    while (currentDate <= maxDate) {
      isoDays.push(DateHelper.toIsoStringInCurrentTimezone(currentDate))
      currentDate.setDate(currentDate.getDate() + 1)
    }

    return isoDays
  }

  /**
   * Gets the end of next week.
   * @param {Date} date
   * @return {Date}
   */
  static getTheEndOfNextWeek(date) {
    const theEndOfNextWeekDate = new Date(date.getTime())

    const lastDayInWeek = DateHelper._getLastDayInWeekValue()
    const endOfNextWeekDateToSet = lastDayInWeek - theEndOfNextWeekDate.getDay() + theEndOfNextWeekDate.getDate() + 7

    theEndOfNextWeekDate.setDate(endOfNextWeekDateToSet)

    return theEndOfNextWeekDate
  }

  static _getLastDayInWeekValue() {
    // TODO or 6 if last day in week is Saturday. Depends on locale
    return 6 + 1
  }
}
