import Vue from 'vue'
import Vuex from 'vuex'
import meet from '@/store/modules/meet'
import settings from '@/store/modules/settings'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    meet,
    settings,
  },
})
