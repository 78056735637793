<template>
  <v-time-picker
      v-bind="$props"
      v-on="$listeners"
      format="24hr"
  ></v-time-picker>
</template>

<script>
export default {
  name: 'TimePickerWidget',

  props: {
    value: {
      required: true,
    },

    readonly: {
      type: Boolean,
      default: false,
    },

    max: {},
    min: {},
  },
}
</script>

<style scoped>

</style>