<template>
  <div class="text-center">
    <h1>{{ $metaInfo.title }}</h1>
  </div>
</template>

<script>
export default {
  name: 'NotFoundComponent',

  metaInfo() {
    return {
      title: this.$t('Page not found'),
    }
  },
}
</script>

<style scoped>

</style>