<template>
  <v-calendar
      ref="calendar"
      v-model="value"
      :weekdays="weekday"
      :type="type"
      :events="events"
      :event-overlap-mode="mode"
      :event-overlap-threshold="30"
      :event-color="getEventColor"
      :first-interval="firstInterval"
      :interval-count="intervalCount"
      :interval-format="intervalFormat"
      @change="getEvents"
  ></v-calendar>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  name: 'MeetCalendar',

  data: () => ({
    value: '',
    type: 'week',
    mode: 'stack',
    weekday: [1, 2, 3, 4, 5, 6, 0],
    events: [],
    colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
    firstInterval: 0,
    intervalCount: 24,
  }),

  computed: {
    ...mapGetters({
      attendees: 'meet/getAttendees',
    }),
  },

  watch: {
    attendees() {
      this.getEvents()
    },
  },

  created() {
    this.getEvents()
  },

  methods: {
    getEvents() {
      const events = []

      let minHour = 23
      let maxHour = 0

      for (const attendee of this.attendees) {
        const {attendeeName, slots} = attendee
        const color = this.colors[this.rnd(0, this.colors.length - 1)]

        for (const slot of slots) {
          for (const slotDate of slot.dates) {
            const start = new Date(slotDate + ' ' + slot.timeRange[0])
            const end = new Date(slotDate + ' ' + slot.timeRange[1])

            minHour = Math.min(minHour, start.getHours())
            maxHour = Math.max(maxHour, end.getHours())

            events.push({
              name: attendeeName,
              start: start,
              end: end,
              color: color,
              timed: true,
            })
          }
        }
      }

      this.firstInterval = minHour
      this.intervalCount = maxHour - minHour

      this.events = events
    },

    getEventColor (event) {
      return event.color
    },

    rnd (a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a
    },

    intervalFormat: (dateTime) =>  dateTime.time,
  },
}
</script>

<style scoped>

</style>