export default {
  namespaced: true,

  state: {
    firstDayOfWeek: 1,
    appName: 'Come To Meet',
  },

  getters: {
    getFirstDayOfWeek: (state) => state.firstDayOfWeek,
    getAppName: (state) => state.appName,
  },
}
