<template>
  <div v-if="timeWithDays">
    <h4>{{ $t('Availability') }}</h4>

    <div v-for="(timeInDay, dayDate) in timeWithDays" :key="dayDate">
      <span><strong>{{ dayDate }}</strong></span>
      <span> at {{ timeInDay }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TimeWithDays',

  props: {
    timeWithDays: {
      type: Object,
    },
  },
}
</script>

<style scoped>

</style>