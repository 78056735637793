<template>
  <div>
    <data-set-dialog @done="done" ref="dialog" :title="$t('Choose location')">
      <div class="container">
        <h3>Pick location</h3>

        <location-picker v-if="showMap" ref="locationPicker"></location-picker>
      </div>
    </data-set-dialog>
  </div>
</template>

<script>
import DataSetDialog from '@/components/Widgets/Dialogs/DataSetDialog'
import LocationPicker from '@/components/Widgets/Maps/Location/LocationPicker'

export default {
  name: 'SetLocation',

  components: {DataSetDialog, LocationPicker},

  data: () => ({
    showMap: false,
  }),

  methods: {
    showDialog() {
      this.$refs.dialog.showDialog()

      this.$nextTick(() => {
        this.showMap = true
      })
    },

    done() {
      this.showMap = false
      this.$emit('done', this.$refs.locationPicker.location)
    },
  },
}
</script>

<style scoped>

</style>