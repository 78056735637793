<template>
  <div class="text-center">
    <h1>{{ $metaInfo.title }}</h1>

    <div class="data-container">
      <div>
        <a href="#" @click.prevent="changeMeetingData">{{ name }}</a>
      </div>

      <div>
        <v-text-field
            v-model="userName"
            :label="$t('Your name')"
            required
        ></v-text-field>
      </div>

      <div>
        <div><strong>{{$t('In date range:')}}</strong></div>
        <div>{{ dateRangeText }}</div>

        <div>
          <set-availability ref="setAvailability" :date-range="dateRange" @done="handleAvailabilitySet"></set-availability>
          <a href="#" @click.prevent="$refs.setAvailability.showDialog()">{{ $t('Set availability') }}</a>
        </div>

        <div class="container">
          <time-with-days :time-with-days="timeWithDays"></time-with-days>
        </div>
      </div>

      <static-map v-if="location" :lat="location.lat" :lng="location.lng"></static-map>
    </div>

    <availability-view class="data-row" v-if="showAvailabilityView" :attendees-data="attendeesData"></availability-view>

    <div v-if="attendeesData" class="data-row">
      <v-btn
          color="secondary"
          @click="showAvailabilityView = true"
      >
        {{$t('Compute meet time')}}
      </v-btn>
    </div>

    <invite-link :meet-id="id"></invite-link>
  </div>
</template>

<script>
import InviteLink from '@/views/Meeting/View/Components/InviteLink'
import SetAvailability from '@/views/Meeting/View/Components/Availability/SetAvailability'
import TimeWithDays from '@/views/Meeting/View/Components/TimeWithDays'
import StaticMap from '@/components/Widgets/Maps/Location/StaticMap'
import 'vue-json-pretty/lib/styles.css'
import AvailabilityView from '@/views/Meeting/View/Components/AvailabilityView/AvailabilityView'

export default {
  name: 'MeetingView',

  metaInfo() {
    return {
      title: this.$t('Let\'s go'),
    }
  },

  components: {InviteLink, SetAvailability, TimeWithDays, StaticMap, AvailabilityView},

  props: {
    id: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    name: null,
    userName: null,
    dateRange: [],
    location: null,
    timeWithDays: {},
    attendeesData: null,
    showAvailabilityView: false,
  }),

  computed: {
    dateRangeText() {
      const startDate = this.dateRange[0] ? this.dateRange[0].toLocaleDateString() : ''
      const endDate = this.dateRange[1] ? this.dateRange[1].toLocaleDateString() : ''

      return startDate + ' ~ ' + endDate
    },
    attendeesDataStorageKey() {
      return this.id + '-availability'
    },
  },

  created() {
    this.initMeetData()
    this.initAttendeesData()
  },

  methods: {
    initMeetData() {
      const jsonData = localStorage.getItem(this.id)

      if (!jsonData) {
        alert('No storage data')
        return
      }

      const data = JSON.parse(jsonData)

      this.name = data.name
      this.dateRange = [new Date(data.dateRange[0]), new Date(data.dateRange[1])]
      this.location = data.location
    },

    initAttendeesData() {
      const attendeesData = localStorage.getItem(this.attendeesDataStorageKey)

      if (attendeesData) {
        this.attendeesData = JSON.parse(attendeesData)
      }
    },

    changeMeetingData() {
      alert('Doesn\'t work yet')
    },

    handleAvailabilitySet(timeWithDays) {
      this.timeWithDays = timeWithDays

      if (!this.attendeesData) {
        this.attendeesData = {}
      }

      this.$set(this.attendeesData, this.userName, this.timeWithDays)
      localStorage.setItem(this.attendeesDataStorageKey, JSON.stringify(this.attendeesData))
    },
  },
}
</script>

<style scoped lang="scss">
.data-container {
  > div {
    margin-top: 20px;
  }
}

.data-row {
  margin: 50px 0;
}
</style>