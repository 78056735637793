<template>
  <v-date-picker
      v-bind="$props"
      v-on="$listeners"
      :range="range"
      :show-adjacent-months="showAdjacentMonths"
      :first-day-of-week="firstDayOfWeek"
  ></v-date-picker>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  name: 'DatePickerWidget',

  props: {
    value: {
      required: true,
    },

    min: {
      type: String,
    },

    max: {
      type: String,
    },

    range: {
      type: Boolean,
      default: false,
    },

    showAdjacentMonths: {
      type: Boolean,
      default: true,
    },

    multiple: {
      type: Boolean,
      default: false,
    },

    allowedDates: {},

    noTitle: {
      type: Boolean,
      default: false,
    },

    showCurrent: {
      type: [Boolean, String],
      default: true,
    },
  },

  computed: {
    ...mapGetters({
      firstDayOfWeek: 'settings/getFirstDayOfWeek',
    }),
  },
}
</script>

<style scoped>

</style>