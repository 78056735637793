<template>
  <bottom-content>
    <v-divider></v-divider>
    <div class="invite-container">
      <div class="invite-text">{{ $t('Invite link') }}</div>
      <v-btn
          class="invite-button"
          color="primary"
          x-large
          @click="copy"
      >
        <v-icon>
          mdi-content-copy
        </v-icon>
      </v-btn>
    </div>

    <v-snackbar
        v-model="showCopied"
        :timeout="1000"
    >
      {{ $t('Copied') }}
    </v-snackbar>

  </bottom-content>
</template>

<script>
import BottomContent from '@/components/Content/BottomContent'

export default {
  name: 'InviteLink',

  components: {BottomContent},

  props: {
    meetId: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    showCopied: false,
  }),

  methods: {
    copy() {
      const link = window.location.origin + '/meeting/' + this.meetId
      navigator.clipboard.writeText(link)

      this.showCopied = true
    },
  },
}
</script>

<style scoped lang="scss">
.invite-container {
  display: grid;
  grid-template-columns: 4fr 1fr;
  align-items: center;
  background: #fff;

  .invite-text {
    font-size: 20px;
  }

  .invite-button {
    position: relative;
  }
}
</style>