<template>
  <div class="map-container">
    <l-map
        :zoom="zoom"
        :center="center"
        :bounds="bounds"
        :max-bounds="maxBounds"
        :style="mapStyle"
    >
      <l-tile-layer
          :url="url"
          :attribution="attribution"
      />
      <l-marker :lat-lng="marker"/>
    </l-map>
  </div>
</template>

<script>
import 'leaflet/dist/leaflet.css'
import {latLng, latLngBounds, Icon} from 'leaflet'
import {LMap, LTileLayer, LMarker} from 'vue2-leaflet'

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

const percent = 0.002

export default {
  name: 'StaticMap',

  components: {
    LMap,
    LTileLayer,
    LMarker,
  },

  props: {
    lat: {
      type: Number,
      required: true,
    },
    lng: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      zoom: 13,
      path: '/images/',
      center: [0, 0],
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    };
  },

  computed: {
    mapStyle() {
      const height = window.innerHeight * 0.25

      return `height: ${height}px; width: 100%`
    },
    bounds() {
      return latLngBounds([
        [this.lat + -percent, this.lng + -percent],
        [this.lat + percent, this.lng + percent],
      ])
    },
    maxBounds() {
      return latLngBounds([
        [this.lat + -percent, this.lng + -percent],
        [this.lat + percent, this.lng + percent],
      ])
    },
    marker() {
      return latLng(this.lat, this.lng)
    },
    location() {
      return [this.lat, this.lng]
    },
  },
}
</script>

<style scoped>

</style>